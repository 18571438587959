import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import { options } from "../components/parser"
import { SoliBlack, SoliTooWhite, device } from "../components/styles"
import Logo from "../images/solides-header-logo2.inline.svg"
import BigFlower from "../images/BigFlower.inline.svg"
import BirdFly from "../images/BirdFly.inline.svg"
import { GatsbyImage } from "gatsby-plugin-image"
//import SoliToSoli from "../components/solitosolides"
import { SoliCardBG } from "../components/styles"
import { SoliCardColor } from "../components/styles"
import { MailLink } from "../components/headerlinks"

const TopLogo = styled(Logo)`
  @${device.laptop} {
    width: 500px;
    height: 500px;
  }
`

const TopLogoDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--indexbg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200% auto;
  @${device.mobileM} {
    height: auto;
    background-size: 150% auto;
  }
  @${device.laptop} {
    background-size: 100% auto;
    height: 70vh;
  }
`

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: var(--border);
  border-bottom: 0;
  overflow: visible;
`

const MainContent = styled.div`
  display: grid;
  grid-gap: 0.3em;
  grid: auto-flow dense / 1fr 1fr 1fr;
`
const MailCard = styled.div`
grid-column: span 3;
overflow: hidden;
position:relative;
display: flex;
flex-direction: column;
justify-content: top;
align-items: top;
text-decoration:none;
color: ${SoliCardColor};
background: ${SoliCardBG};
background-repeat:norepeat,repeat;
border: var(--border);
@${device.mobileL} {
  grid-column: span 1;
}
`

const BigCard = styled(Link)`
grid-column: span 3;
overflow: hidden;
position:relative;
text-decoration:none;
color: ${SoliCardColor};
background: ${SoliCardBG};
background-repeat:norepeat,repeat;
border: var(--border);
@${device.mobileL}{
  grid-column: span 2;

}
&:visited{color:inherit;}
}
`
const BlockImage = styled(GatsbyImage)`
  display: block;
`

/*background-image: var(--bgcolorfoot), url(${angerichtet});
background-position:center;
background-size:cover;*/

const SmallCard = styled(BigCard)`
  grid-column: span 3;
  overflow: hidden;
  @${device.mobileL} {
    grid-column: span 1;
  }
`
const SmallProjektCard = styled(BigCard)`
  grid-column: span 1;
  @${device.mobileL} {
    overflow: hidden;
    grid-column: span 1;
  }
`

const TerminText = styled.div`
  text-decoration: none;
  width: 100%;
  color: black;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 0.5rem;
  margin: 0;
  font-family: "Alegreya Sans";
  text-align: center;
  padding: 0.5rem 0.5rem;
`
/*
const RootsLeft = styled(BigRoots)`
  float: left;
  max-width: 35%;
  max-height: 200px;
`
*/
const FlowerLeft = styled(BigFlower)`
  float: left;
  max-width: 35%;
  max-height: 200px;
`

const FlyRight = styled(BirdFly)`
  float: right;
  max-width: 35%;
  max-height: 200px;
`
const TextSection = styled.div`
  min-width: 150px;

  font-size: 1.2rem;
  line-height: 1.4;
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  font-family: "Alegreya Sans";
  @${device.mobileL} {
  }
`
const Container = styled.div`
  position: relative;
`

const Text = styled(SoliTooWhite)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: black;
  font-size: 7vmin;
  z-index: 1;
  @${device.laptop} {
  }
  &:hover {
    text-shadow: var(--pinktextshadow);
  }
  ${Container}:hover & {
    text-shadow: var(--pinktextshadow);
  }
`
const TopTop = () => (
  <>
    <TopContent>
      <TopLogoDiv>
        <TopLogo />
      </TopLogoDiv>
    </TopContent>
  </>
)

const Termine = ({
  data: {
    Termine: {
      html,
      frontmatter: { title, location },
    },
  },
}) => {
  return (
    <MailCard>
      <SoliBlack>{title}</SoliBlack>
      <TerminText>{parse(html, options)}</TerminText>
      <TextSection>
      <SoliBlack> SoliMail
      </SoliBlack>
      <MailLink/>
      Einmal im Quartal verschicken wir eine Rundmail, mit allem was bei uns und unseren SoliProjekten so passiert. Hier kannst du dich anmelden!
      </TextSection>
    </MailCard>
  )
}

const Blog = ({
  data: {
    Blogbild,
    Blog: {
      frontmatter: { bild, bildtext },
    },
    BlogPost,
  },
}) => {
  const LastBlogPost = BlogPost.edges[0].node

  return (
    <BigCard
      to={
        `/` +
        LastBlogPost.frontmatter.location +
        `/#` +
        LastBlogPost.frontmatter.title.replace(/[^a-zA-Z0-9]/g, "")
      }
      key={LastBlogPost.id}
    >
      <Text>{LastBlogPost.frontmatter.title}</Text>
      <BlockImage
        image={Blogbild.childImageSharp.gatsbyImageData}
        alt={bildtext}
        objectFit="cover"
        style={{ position: "static" }}
      />
    </BigCard>
  )
}

const SoliProjekte = ({
  data: {
    Projekte: {
      html,
      id,
      frontmatter: { title, bild, bildtext, linkto },
    },
  },
}) => {
  return (
    <SmallCard to={`/` + linkto} key={id}>
      <FlowerLeft />

      <SoliBlack>
        Soli
        <wbr />
        Projekte
      </SoliBlack>
      <TextSection>{parse(html, options)}</TextSection>
    </SmallCard>
  )
}
//test

const ProjektCards = ({ data }) => {
  const FindeBild = (bild) =>
    data.ProjektBilder.edges.find(
      (g) => g.node.childImageSharp.parent.name === bild
    ).node.childImageSharp.gatsbyImageData
  return (
    <>
      {data.DreiProjekte.edges.map(
        (
          {
            node: {
              frontmatter: { link, bild, bildtext },
            },
          },
          id
        ) => (
          <SmallProjektCard to={`/` + link} key={id}>
            <BlockImage image={FindeBild(bild)} alt={bildtext} key={id} />
          </SmallProjektCard>
        )
      )}
    </>
  )
}

const Bier = ({
  data: {
    Mockup,
    Bier: {
      html,
      id,
      frontmatter: { title, location, bild, bildtext, linkto },
    },
  },
}) => {
  return (
    <SmallCard to={`/` + linkto} key={id}>
      <GatsbyImage
        image={Mockup.childImageSharp.gatsbyImageData}
        alt={bildtext}
        style={{
          float: "right",
          objectFit: "contain",
          height: "95%",
          transform: "rotate(15deg)",
        }}
        objectFit="contain"
      />
      <SoliBlack>{title}</SoliBlack>
      <TextSection>{parse(html, options)}</TextSection>
    </SmallCard>
  )
}

const BierHier = ({
  data: {
    Vertrieb: {
      html,
      id,
      frontmatter: { title, location, bild, bildtext, linkto },
    },
  },
}) => {
  return (
    <SmallCard to={`/` + linkto} key={id} style={{ gridColumnEnd: "-1" }}>
      <SoliBlack>
        <FlyRight />
        {title}
      </SoliBlack>
      <TextSection>{parse(html, options)}</TextSection>
    </SmallCard>
  )
}

const Index = ({ data }) => {
  return (
    <Layout site="index" topcontent={<TopTop data={data} />}>
      <MainContent>
        <Blog data={data} key="Blog" />
        <Termine data={data} key="Termine" />
        <Bier data={data} key="Bier" />
        <SoliProjekte data={data} key="Projekte" />
        <BierHier data={data} key="BierHier" />

        <ProjektCards data={data} key="ProjektCards" />
      </MainContent>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query IndexQuery {
    BlogPost: allMarkdownRemark(
      filter: {
        frontmatter: { online: { eq: "ja" }, location: { eq: "blog" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          id
          frontmatter {
            date(formatString: "DD. MMMM 'YY", locale: "de-DE")
            title
            online
            location
            bild
            bildtext
          }
        }
      }
    }
    Blog: markdownRemark(
      frontmatter: { location: { eq: "Startseite/#blog" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    Projekte: markdownRemark(
      frontmatter: { location: { eq: "Startseite/#projekte" } }
    ) {
      id
      frontmatter {
        linkto
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    DreiProjekte: allMarkdownRemark(
      filter: { frontmatter: { location: { eq: "Startseite/#dreiprojekte" } } }
      sort: { fields: frontmatter___reihenfolge }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            bild
            bildtext
            link
          }
        }
      }
    }
    Bier: markdownRemark(
      frontmatter: { location: { eq: "Startseite/#bier" } }
    ) {
      id
      frontmatter {
        linkto
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    Vertrieb: markdownRemark(
      frontmatter: { location: { eq: "Startseite/#vertrieb" } }
    ) {
      id
      frontmatter {
        linkto
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    Termine: markdownRemark(
      frontmatter: { location: { eq: "blog/#Termine" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    Blogbild: file(relativePath: { eq: "media/radentscheid.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
        parent {
          ... on File {
            id
            name
          }
        }
      }
    }

    ProjektBilder: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "SoliProjekte" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400

              transformOptions: { fit: COVER }
            )
            parent {
              ... on File {
                id
                name
              }
            }
          }
        }
      }
    }
    Mockup: file(relativePath: { eq: "Solides.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, height: 250)
        parent {
          ... on File {
            id
            name
          }
        }
        id
      }
    }
  }
`
